'use client';

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { node, object } from 'prop-types';

import { toRootCssVariables } from '@personly/libs-css';

const SchemesContext = createContext();

const SchemesProvider = ({ children, cookie, schemes, tld }) => {
  const [schemeHandle, setSchemeHandle] = useState(schemes.scheme);

  const schemeValid = useCallback(
    (scheme) => schemes.schemes.map(({ id }) => id).includes(scheme),
    [schemes.schemes]
  );

  useEffect(() => {
    (async () => {
      const cookieSchemeHandle = await cookie.get('schemeHandle');
      if (cookieSchemeHandle && schemeValid(cookieSchemeHandle)) {
        setSchemeHandle(cookieSchemeHandle);
      }
    })();
  }, [cookie, schemeValid]);

  const setBrowserSchemeHandle = useCallback(
    (schemeHandle) => {
      (async () => {
        const cookieSchemeHandle = await cookie.get('schemeHandle');
        const differs = cookieSchemeHandle !== schemeHandle;
        const valid = !!cookieSchemeHandle && schemeValid(cookieSchemeHandle);
        const canChange = !cookieSchemeHandle || valid;
        if (differs && canChange) {
          const domain = await tld.getDomain(window.location.hostname);

          await cookie.set('schemeHandle', schemeHandle, { domain });
          setSchemeHandle(schemeHandle);
        }
      })();
    },
    [cookie, tld, schemeValid]
  );

  const browserSchemeHandle = useMemo(() => {
    if (schemeHandle === 'SYSTEM') {
      if (window.matchMedia) {
        const colorSchemes = schemes.schemes
          .filter(({ browser }) => browser)
          .map(({ id }) => id);

        for (const colorScheme of colorSchemes) {
          const media = `(prefers-color-scheme: ${colorScheme.toLowerCase()})`;

          if (window.matchMedia(media).matches) return colorScheme;
        }

        return schemes.scheme;
      }
    }

    return schemeHandle;
  }, [schemeHandle, schemes]);

  const cssVariables = useMemo(
    () =>
      schemes.cssVariables[browserSchemeHandle].reduce((acc, cur) => {
        if (cur.value.length > 1) {
          return { ...acc, [cur.key]: cur.value };
        }

        return { ...acc, [cur.key]: cur.value };
      }, {}),
    [schemes.cssVariables, browserSchemeHandle]
  );

  const rootCssVariables = useMemo(
    () => toRootCssVariables(schemes.cssVariables[browserSchemeHandle]),
    [browserSchemeHandle, schemes.cssVariables]
  );

  useEffect(() => {
    const root = document.querySelector(':root').style;

    for (const { key, value } of rootCssVariables) {
      root.setProperty(`--${key}`, value);
    }
  }, [rootCssVariables]);

  const value = useMemo(
    () => ({
      schemeHandle: browserSchemeHandle,
      setSchemeHandle: setBrowserSchemeHandle,
      cssVariables,
    }),
    [setBrowserSchemeHandle, browserSchemeHandle, cssVariables]
  );

  return (
    <SchemesContext.Provider value={value}>{children}</SchemesContext.Provider>
  );
};

SchemesProvider.propTypes = {
  children: node.isRequired,
  cookie: object.isRequired,
  schemes: object.isRequired,
  tld: object.isRequired,
};

function useSchemes() {
  const context = useContext(SchemesContext);
  if (context === undefined) throw new Error('useSchemes requires a context!');

  return context;
}

export { SchemesProvider, useSchemes };
