import { kebabCase } from 'change-case';

const toRootCssVariables = (cssVariables) =>
  cssVariables.reduce((acc, { key, value }) => {
    if (value.length > 1) {
      acc.push(
        ...value.reduce(
          (a, b, i) => [...a, { key: `${kebabCase(key)}-${i}`, value: b }],
          []
        )
      );
    } else acc.push({ key: kebabCase(key), value: value[0] });

    return acc;
  }, []);

const toCss = (cssVariables) => {
  const rootCssVariables = toRootCssVariables(cssVariables);

  return `:root {
${rootCssVariables
  .reduce((acc, cur) => [...acc, `  --${cur.key}: ${cur.value};`], [])
  .join(`\n`)}
}
`;
};

const toTailwindCss = (cssVariables) => {
  const rootCssVariables = toRootCssVariables(cssVariables);

  return `@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
${rootCssVariables
  .reduce((acc, cur) => [...acc, `    --${cur.key}: ${cur.value};`], [])
  .join(`\n`)}
  }
}
`;
};

const toTailwindColors = (cssVariables) =>
  cssVariables
    .reduce((acc, { key, value }) => {
      if (value.length > 1) {
        acc.push(
          ...value.reduce(
            (a, _, i) => [
              ...a,
              {
                key: `${kebabCase(key)}${i}`,
                value: `var(--${kebabCase(key)}-${i})`,
              },
            ],
            []
          )
        );
      } else
        acc.push({
          key: kebabCase(key),
          value: `var(--${kebabCase(key)})`,
        });

      return acc;
    }, [])
    .reduce((acc, cur) => ({ ...acc, [cur.key]: cur.value }), {});

export { toCss, toRootCssVariables, toTailwindColors, toTailwindCss };
