'use client';

import Image from 'next/image';
import { string } from 'prop-types';

import { useSchemes } from '@personly/libs-providers';

const CompanyImage = ({ alt }) => {
  const { schemeHandle } = useSchemes();

  const src = `/images/personly-${schemeHandle.toLowerCase()}.svg`;

  return <Image src={src} width={104} height={26} alt={alt} />;
};

CompanyImage.propTypes = {
  alt: string.isRequired,
};

export default CompanyImage;
